const ADD_COLLECTION = 'ADD_COLLECTION';
const CHECKED_COLLECTION_ITEMS = 'CHECKED_COLLECTION_ITEMS';
const COLLECTION_UPDATE = 'COLLECTION_UPDATE';
const COLLECTIONS_USERS_UPDATE = 'COLLECTIONS_USERS_UPDATE';
const GET_COLLECTIONS_LIST = 'GET_COLLECTIONS_LIST';
const GET_COLLECTIONS_LIST_SUCCESS = 'GET_COLLECTIONS_LIST_SUCCESS';
const GET_COLLECTIONS_LIST_FAILURE = 'GET_COLLECTIONS_LIST_FAILURE';
const SET_COLLECTION_PRIORITY = 'SET_COLLECTION_PRIORITY';
const COLLECTIONS_LIST_FILTERED = 'COLLECTIONS_LIST_FILTERED';
const COLLECTION_ID = 'COLLECTION_ID';
const REMOVE_COLLECTION = 'REMOVE_COLLECTION';

const initialState = {
  new_collection: [],
  checked_items: [],
  collection_update: [],
  collection_users_update: [],
  collections_list: [],
  collection_priority: [],
  collection_id: {},
  remove_collection: [],
  search: [],
  loading: false,
  error: false,
};

export default function collectionsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_COLLECTION:
      return {
        ...state,
        new_collection: action.payload,
      };
    case CHECKED_COLLECTION_ITEMS:
      return {
        ...state,
        checked_items: action.payload,
      };
    case COLLECTION_UPDATE:
      return {
        ...state,
        collection_update: action.payload,
      };
    case GET_COLLECTIONS_LIST:
      return {
        ...state,
        loading: true,
      };
    case GET_COLLECTIONS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        collections_list: action.payload,
      };
    case GET_COLLECTIONS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        collections_list: [],
      };
    case COLLECTIONS_LIST_FILTERED:
      return {
        ...state,
        search: action.payload,
      };
    case SET_COLLECTION_PRIORITY:
      return {
        ...state,
        collection_priority: action.payload,
      };
    case COLLECTION_ID:
      return {
        ...state,
        collection_id: action.payload,
      };
    case REMOVE_COLLECTION:
      return {
        ...state,
        remove_collection: state.collections_list.filter(
          (collection) => collection.id !== action.payload
        ),
      };
    case COLLECTIONS_USERS_UPDATE:
      return {
        ...state,
        loading: false,
        error: false,
        collection_users_update: action.payload,
      };
    default:
      return state;
  }
}

export const addCollection = (new_collection) => ({
  type: ADD_COLLECTION,
  payload: new_collection,
});

export const checkedCollectionItems = (checked_items) => ({
  type: CHECKED_COLLECTION_ITEMS,
  payload: checked_items,
});

export const collectionUpdate = (collection_update) => ({
  type: COLLECTION_UPDATE,
  payload: collection_update,
});

export const collectionUsersUpdate = (collection_users_update) => ({
  type: COLLECTIONS_USERS_UPDATE,
  payload: collection_users_update,
});

export const getCollectionsList = () => ({
  type: GET_COLLECTIONS_LIST,
});

export const getCollectionsListSuccess = (collections_list) => ({
  type: GET_COLLECTIONS_LIST_SUCCESS,
  payload: collections_list,
});

export const getCollectionsListFailure = (error) => ({
  type: GET_COLLECTIONS_LIST_FAILURE,
  payload: error,
});

export const setCollectionPriority = (collection_priority) => ({
  type: SET_COLLECTION_PRIORITY,
  payload: collection_priority,
});

export const collectionId = (collection_id) => ({
  type: COLLECTION_ID,
  payload: collection_id,
});

export const removeCollection = (remove_collection) => ({
  type: REMOVE_COLLECTION,
  payload: remove_collection,
});

export const searchCollection = (search) => ({
  type: COLLECTIONS_LIST_FILTERED,
  payload: search,
});
