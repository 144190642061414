import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { collectionId } from '../../store/collectionsReducer';
import ModalContext from '../../contexts/modal-context';

import { TableRow, Tooltip, Typography, IconButton } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { TableBodyCell } from '../table-cells/TableCells';

import Delete from '../../icons/Delete';
import Edit from '../../icons/EditIcon';

import appStyles from '../app/App.module.scss';

import './Collections.scss';

const CollectionsListItem = ({
  id,
  priority,
  name,
  policy,
  users,
  dataposition,
  onDragStart,
  onDragOver,
  onDrop,
  onDragLeave,
  onChangePriority,
}) => {
  const dispatch = useDispatch();
  const profilesList = useSelector((state) => state.profiles.profiles);
  const { isShowRemoveModal, setIsShowRemoveModal } = useContext(ModalContext);

  const onToggleRemoveModal = () => {
    dispatch(
      collectionId({
        id: id,
        collection_name: name,
      })
    );

    return setIsShowRemoveModal(!isShowRemoveModal);
  };

  return (
    <TableRow
      key={id}
      draggable
      data-position={dataposition}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragLeave={onDragLeave}
      onDragEnd={onChangePriority}
    >
      <TableBodyCell scope="row">
        <Tooltip
          arrow
          placement="right"
          title={
            <Typography>
              Hold down the icon and move the line to change the priority of the
              Collection
            </Typography>
          }
        >
          <DragIndicatorIcon className="drag-icon" />
        </Tooltip>
      </TableBodyCell>
      <TableBodyCell align="center">{priority}</TableBodyCell>
      <TableBodyCell align="left">{name}</TableBodyCell>
      <TableBodyCell align="left">
        {profilesList.map((profile) =>
          profile.id === policy ? profile.name : ''
        )}
      </TableBodyCell>
      <TableBodyCell align="left">{users}</TableBodyCell>
      <TableBodyCell align="right">
        <NavLink to={id.toString()}>
          <IconButton className={appStyles['MuiIconButton-root']}>
            <Edit />
          </IconButton>
        </NavLink>
      </TableBodyCell>
      <TableBodyCell align="left">
        <IconButton
          className={appStyles['MuiIconButton-root']}
          onClick={onToggleRemoveModal}
        >
          <Delete />
        </IconButton>
      </TableBodyCell>
    </TableRow>
  );
};

export default CollectionsListItem;
