const ADD_DOMAIN = "ADD_DOMAIN";
const GET_DOMAINS_LIST = "GET_DOMAINS_LIST";
const GET_DOMAINS_LIST_SUCCESS = "GET_DOMAINS_LIST_SUCCESS";
const GET_DOMAINS_LIST_FAILURE = "GET_DOMAINS_LIST_FAILURE";
const DOMAINS_LIST_FILTERED = "DOMAINS_LIST_FILTERED";
const UPDATE_DOMAIN = "UPDATE_DOMAIN";
const REMOVE_DOMAIN = "REMOVE_DOMAIN";
const DOMAIN_ID = "DOMAIN_ID";

const initialState = {
    new_domain: [],
    domains_list: [],
    domain_id: {},
    update_domain: [],
    remove_domain: [],
    search: [],
    loading: false,
    error: false,
};

export default function domainsReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_DOMAIN:
            return {
                ...state,
                new_domain: action.payload
            }
        case GET_DOMAINS_LIST:
            return {
                ...state,
                loading: true,
                error: null,
                domains_list: []
            }
        case GET_DOMAINS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                domains_list: action.payload
            }
        case GET_DOMAINS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                domains_list: []
            }
        case DOMAINS_LIST_FILTERED:
            return {
                ...state,
                search: action.payload
            }
        case DOMAIN_ID:
            return {
                ...state,
                domain_id: action.payload
            }
        case REMOVE_DOMAIN:
            return {
                ...state,
                remove_domain: state.domains_list.filter(domain => domain.id !== action.payload)
            }
        case UPDATE_DOMAIN:
            return {
                ...state,
                update_domain: action.payload
            }
        default:
            return state
    };
};

export const addDomain = (new_domain) => (
    {
        type: ADD_DOMAIN,
        payload: new_domain
    }
);

export const getDomainsList = () => (
    {
        type: GET_DOMAINS_LIST
    }
);

export const getDomainsListSuccess = (domains_list) => (
    {
        type: GET_DOMAINS_LIST_SUCCESS,
        payload: domains_list
    }
);

export const getDomainsListFailure = (error) => (
    {
        type: GET_DOMAINS_LIST_FAILURE,
        payload: error
    }
);

export const domainId = (domain_id) => (
    {
        type: DOMAIN_ID,
        payload: domain_id
    }
);

export const removeDomain = (remove_domain) => (
    {
        type: REMOVE_DOMAIN,
        payload: remove_domain
    }
);

export const updateDomain = (update_domain) => (
    {
        type: UPDATE_DOMAIN,
        payload: update_domain
    }
);


export const searchDomain = (search) => (
    {
        type: DOMAINS_LIST_FILTERED,
        payload: search
    }
);
