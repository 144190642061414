import { Paper } from '@mui/material';

import styles from './Paper.module.scss';

const primary = styles['MuiPaper-root'] + ' ' + styles['primary'];
const secondary = styles['MuiPaper-root'] + ' ' + styles['secondary'];

const PrimaryPaper = ({ children }) => (
  <Paper classes={{ root: primary }} variant="outlined">
    {children}
  </Paper>
);

export { PrimaryPaper };

const SecondaryPaper = ({ children }) => (
  <Paper classes={{ root: secondary }} variant="outlined">
    {children}
  </Paper>
);

export { SecondaryPaper };
