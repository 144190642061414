const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS';

const initialState = {
  notification_success: {
    isNotification: false,
    type: '',
    action: '',
    message: '',
  },
};

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification_success: action.payload,
      };
    default:
      return state;
  }
}

export const notificationSuccess = (notification_success) => ({
  type: NOTIFICATION_SUCCESS,
  payload: notification_success,
});
