import { SvgIcon } from '@mui/material';

const Delete = ({ sx }) => (
  <SvgIcon sx={sx}>
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16699 10.0833V15.5833M12.8337 10.0833V15.5833M3.66699 6.41667H18.3337M5.50033 6.41667V16.5C5.50033 18.0188 6.73154 19.25 8.25033 19.25H13.7503C15.2691 19.25 16.5003 18.0188 16.5003 16.5V6.41667H5.50033ZM13.7503 6.41667V4.58333C13.7503 3.57081 12.9295 2.75 11.917 2.75H10.0837C9.07114 2.75 8.25033 3.57081 8.25033 4.58333V6.41667H13.7503Z"
        stroke="#141C24"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default Delete;
