import { getAuth, getAuthSuccess, getAuthFailure } from '../store/authReducer';
import axios from 'axios';

import { domain } from '../api/httpClient';

export const fetchAuthData = () => {
  return function (dispatch) {
    dispatch(getAuth());

    axios({
      method: 'POST',
      url: `${domain}/auth/is_authenticate`,
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.is_authenticated) {
          dispatch(getAuthSuccess(response.data));
        } else {
          window.location.replace(`${domain}/auth/signin/`);
        }
      })

      .catch(() => {
        dispatch(getAuthFailure());
        window.location.replace(`${domain}/auth/signin/`);
      });
  };
};
