const ADD_GROUP = "ADD_GROUP";
const GET_GROUPS_LIST = "GET_GROUPS_LIST";
const GET_GROUPS_LIST_SUCCESS = "GET_GROUPS_LIST_SUCCESS";
const GET_GROUPS_LIST_FAILURE = "GET_GROUPS_LIST_FAILURE";

const initialState = {
    new_group: [],
    groups_list: [],
    search: [],
    loading: false,
    error: false,
};

export default function groupsReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_GROUP:
            return {
                ...state,
                new_group: action.payload
            }
        case GET_GROUPS_LIST:
            return {
                ...state,
                loading: true
            }
        case GET_GROUPS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                groups_list: action.payload
            }
        case GET_GROUPS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                groups_list: []
            }
        default:
            return state
    };
};

export const addGroup = (new_group) => (
    {
        type: ADD_GROUP,
        payload: new_group
    }
);

export const getGroupsList = () => (
    {
        type: GET_GROUPS_LIST
    }
);

export const getGroupsListSuccess = (groups_list) => (
    {
        type: GET_GROUPS_LIST_SUCCESS,
        payload: groups_list
    }
);

export const getGroupsListFailure = (error) => (
    {
        type: GET_GROUPS_LIST_FAILURE,
        payload: error
    }
);
