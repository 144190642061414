import axios from 'axios';

const BASE_DOMAIN = process.env.REACT_APP_BASE_URL;
const PRODUCTION_DOMAIN = process.env.REACT_APP_PROD_URL;

console.log(process.env.REACT_APP_BASE_URL);

export const domain =
  process.env.NODE_ENV === 'development' ? BASE_DOMAIN : PRODUCTION_DOMAIN;

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});
