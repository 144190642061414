import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import TextField from '../../text-field/TextField';

import { searchUser } from '../../../store/usersReducer';
import { searchCollection } from '../../../store/collectionsReducer';

// import './Search.scss';

const Search = ({ placeholder }) => {
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  const searchHandler = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    dispatch(searchUser(search));
    dispatch(searchCollection(search));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <TextField
      id="search-field"
      value={search}
      label="Search"
      size="small"
      placeholder={placeholder}
      onChange={searchHandler}
    />
  );
};

export default Search;
