import React from 'react';
import { useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import DomainsListItem from './DomainsListItem';
import RemoveDomain from './RemoveDomain';
import UpdateDomain from './UpdateDomain';
import FetchError from '../UI/fetch-error/FetchError';
import Loader from '../UI/loader/Loader';
import { TableHeadCell } from '../table-cells/TableCells';
import { PrimaryPaper } from '../papers/Papers';
import Paginator from '../UI/paginator/Paginator';

import usePagination from '../../hooks/usePagination';

import './Domains.scss';

const DomainsList = () => {
  const { error, loading } = useSelector((state) => state.domains);
  const domains = useSelector((state) => state.domains.domains_list);

  // I'm leaving code below 'cause I might need it in near future
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // const handleChangePage = (_event, newPage) => {
  //   console.log(newPage);
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   console.log(rowsPerPage);
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const itemsCount = 5;
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    gaps,
    setPage,
    totalPages,
  } = usePagination({
    contentPerPage: itemsCount,
    count: domains && domains.length,
  });

  const domainItem = (id, name, groups, users) => {
    return (
      <DomainsListItem
        key={id}
        id={id}
        domain={name}
        groups={`${groups} Groups`}
        users={`${users} Users`}
      />
    );
  };

  if (error) {
    return <FetchError />;
  }

  if (!domains && !loading && !error) {
    return <p className="domains-list-empty">No Domains yet</p>;
  }

  return (
    <PrimaryPaper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell>Domains</TableHeadCell>
              <TableHeadCell>Groups</TableHeadCell>
              <TableHeadCell>Users</TableHeadCell>
              <TableHeadCell></TableHeadCell>
              <TableHeadCell></TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {domains?.map((item) =>
              domainItem(
                item.id,
                item.name,
                item.groups_count,
                item.ad_users_count
              )
            )} */}

            {loading ? (
              <Loader text="Please wait..." />
            ) : (
              domains
                ?.slice(firstContentIndex, lastContentIndex)
                .map((item) =>
                  domainItem(
                    item.id,
                    item.name,
                    item.groups_count,
                    item.ad_users_count
                  )
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={domains.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}

      {domains.length > itemsCount && (
        <Paginator
          nextPage={nextPage}
          prevPage={prevPage}
          page={page}
          gaps={gaps}
          setPage={setPage}
          totalPages={totalPages}
        />
      )}

      <RemoveDomain />
      <UpdateDomain />
    </PrimaryPaper>
  );
};

export default DomainsList;
