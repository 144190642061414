import React, { useContext } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, Dialog, Box } from '@mui/material';
import GppBadOutlinedIcon from '@mui/icons-material/GppBadOutlined';

import { domain } from '../../api/httpClient';
import { removeCollection } from '../../store/collectionsReducer';
import { notificationSuccess } from '../../store/notificationReducer';
import ModalContext from '../../contexts/modal-context';

import './Collections.scss';

const RemoveCollection = () => {
  const dispatch = useDispatch();
  const isAuthData = useSelector((state) => state.auth.auth);
  const collectionData = useSelector(
    (state) => state.collections.collection_id
  );
  const { isShowRemoveModal, setIsShowRemoveModal } = useContext(ModalContext);

  const notificationRemove = {
    isNotification: true,
    type: 'success',
    action: 'remove_domain',
    message: `${collectionData.collection_name} was successfully deleted`,
  };

  const onToggleModal = () => {
    return setIsShowRemoveModal(!isShowRemoveModal);
  };

  const onRemoveCollection = () => {
    axios({
      method: 'DELETE',
      url: `${domain}/ad/rest_api/v1/users/${isAuthData.user_id}/collections/${collectionData.id}/`,
      withCredentials: true,
      data: {
        user_id: isAuthData.user_id,
        collection_id: collectionData.id,
      },
    })
      .then((response) => {
        dispatch(removeCollection(collectionData.id));
        dispatch(notificationSuccess(notificationRemove));
        setIsShowRemoveModal(!isShowRemoveModal);
      })
      .catch(() => {
        console.log('error');
      });
  };

  return (
    <Dialog open={isShowRemoveModal}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '48px',
          height: '48px',
          margin: 'auto',
          borderRadius: '50%',
          backgroundColor: 'warning.main',
        }}
      >
        <GppBadOutlinedIcon
          fontSize="medium"
          sx={{ color: 'primary.contrastText' }}
        />
      </Box>
      <h3>
        Are you sure you want to delete <br /> the{' '}
        {collectionData.collection_name}?
      </h3>
      <Grid container gap={2} className="domains-list-remove-controls">
        <Button
          variant="error"
          onClick={onRemoveCollection}
          size="small"
          sx={{
            flex: '1 0',
            backgroundColor: 'error.main',
            color: '#fff',
            '&:hover': { backgroundColor: 'error.main' },
          }}
        >
          Delete
        </Button>
        <Button variant="outlined" onClick={onToggleModal} size="small">
          Cancel
        </Button>
      </Grid>
    </Dialog>
  );
};

export default RemoveCollection;
