// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryMain": "#1c66f6",
	"primary700": "#0052cc",
	"navBg": "#f6f9ff",
	"warning": "#f64c4c",
	"white": "#fff",
	"neutralGray300": "#ced2da",
	"textColor": "#141c24",
	"orange": "#ec7d0d",
	"error500": "#f64c4c"
};
export default ___CSS_LOADER_EXPORT___;
