import {
  getProfilesSuccess,
  getProfilesFailure,
} from '../store/profilesReducer';
import axios from 'axios';

import { domain } from '../api/httpClient';

export const fetchProfilesList = (user_id) => {
  return function (dispatch) {
    axios({
      method: 'GET',
      url: `${domain}/dashboard/rest_api/v1/users/${user_id}/profiles/`,
      withCredentials: true,
    })
      .then((response) => {
        dispatch(getProfilesSuccess(response.data.results));
      })
      .catch(() => {
        dispatch(getProfilesFailure());
      });
  };
};
