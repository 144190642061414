import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Alert } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

import { notificationSuccess } from '../../../store/notificationReducer';

import './Notification.scss';

const Notification = () => {
  const dispatch = useDispatch();
  const notificationData = useSelector(
    (state) => state.notification.notification_success
  );
  const [isNotification, setIsNotification] = useState(
    notificationData.isNotification
  );

  useEffect(() => {
    const notificationDefault = {
      isNotification: false,
      type: '',
      action: '',
      message: '',
    };

    if (notificationData.isNotification) {
      setIsNotification(true);

      setTimeout(() => {
        setIsNotification(false);

        dispatch(notificationSuccess(notificationDefault));
      }, 3000);
    }
  }, [notificationData, dispatch]);

  let icon;
  switch (notificationData.type) {
    case 'success':
      icon = <CheckCircleIcon />;
      break;
    case 'error':
      icon = <ErrorIcon />;
      break;
    default:
      icon = <></>;
  }

  return (
    isNotification && (
      <Alert severity={notificationData.type} icon={icon}>
        {notificationData.message}
      </Alert>
    )
  );
};

export default Notification;
