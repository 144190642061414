import React from 'react';
import { useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import useSortable from '../../hooks/useSortable';
import usePagination from '../../hooks/usePagination';
import UsersListItem from './UsersListItem';
import FetchError from '../UI/fetch-error/FetchError';
import Loader from '../UI/loader/Loader';
import { TableHeadCell } from '../table-cells/TableCells';
import Tooltip from '../tooltip/Tooltip';
import Paginator from '../UI/paginator/Paginator';

import { ReactComponent as InfoIcon } from '../../icons/info.svg';

import styles from './Users.module.scss';

const UsersList = () => {
  const { error, loading } = useSelector((state) => state.users);
  const users = useSelector(
    (state) => state.adUsersWithProfiles.ad_users_with_profiles
  );
  const collections = useSelector(
    (state) => state.collections.collections_list
  );
  const searchResult = useSelector((state) => state.users.search);
  const usersAssigned = useSelector((state) => state.users.assigned);

  // Might need used this in future
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // const handleChangePage = (_event, newPage) => {
  //   console.log(newPage);
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   console.log(rowsPerPage);
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  let { result, requestSort } = useSortable(users);

  const itemsCount = 5;
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    gaps,
    setPage,
    totalPages,
  } = usePagination({
    contentPerPage: itemsCount,
    count: result && result.length,
  });

  if (searchResult.length > 0) {
    result = result.filter((item) => {
      return (
        item.name.toLocaleLowerCase().match(searchResult.toLocaleLowerCase()) ||
        item.last_seen
          .toLocaleLowerCase()
          .match(searchResult.toLocaleLowerCase())
      );
    });
  }

  if (usersAssigned.filter === 'assigned') {
    result = result.filter((item) => {
      return usersAssigned.data.includes(item.id);
    });
  }

  if (usersAssigned.filter === 'unassigned') {
    result = result.filter((item) => {
      return !usersAssigned.data.includes(item.id);
    });
  }

  const userItem = (id, name, collections, policy, pc, os, last_seen) => {
    return (
      <UsersListItem
        key={id}
        id={id}
        user_name={name}
        collections={collections}
        policy={policy}
        pc={pc}
        os={os}
        last_seen={last_seen}
      />
    );
  };

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - result.length) : 0;

  if (loading) {
    return <Loader text="Please wait..." />;
  }

  if (error) {
    return <FetchError />;
  }

  if (!result && !loading && !error) {
    return <p className={styles['users-list-empty']}>No Users yet</p>;
  }

  return (
    <React.Fragment>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="users list">
          <TableHead>
            <TableRow>
              <TableHeadCell>Users</TableHeadCell>
              <TableHeadCell align="left">Collections</TableHeadCell>
              <TableHeadCell align="left">
                Policy
                <Tooltip
                  arrow
                  classes={{
                    tooltip: styles['MuiTooltip-tooltip'],
                    arrow: styles['MuiTooltip-arrow'],
                  }}
                  title="The active policy specified is from the collection with the highest priority. You can change the priority of a collection in the Collections tab"
                >
                  <InfoIcon />
                </Tooltip>
              </TableHeadCell>
              <TableHeadCell align="left">PC</TableHeadCell>
              <TableHeadCell align="left">OS</TableHeadCell>
              <TableHeadCell
                align="left"
                onClick={() => requestSort('last_seen')}
              >
                Last seen
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {result?.slice(firstContentIndex, lastContentIndex).map((item) =>
              userItem(
                item.id,
                item.name,
                collections.map((collection, index) => {
                  if (collection.ad_users.indexOf(item.id) >= 0) {
                    return <p key={index}>{collection.name}</p>;
                  }
                }),
                item.profile_id,
                item.hostname,
                item.os_info,
                item.last_seen.slice(0, 10)
              )
            )}

            {/* {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
      {result.length > itemsCount && (
        <Paginator
          nextPage={nextPage}
          prevPage={prevPage}
          page={page}
          gaps={gaps}
          setPage={setPage}
          totalPages={totalPages}
        />
      )}
    </React.Fragment>
  );
};

export default UsersList;
