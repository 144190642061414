import React from 'react';
import { useSelector } from 'react-redux';
import { TableRow } from '@mui/material';

import { TableBodyCell } from '../table-cells/TableCells';

const UsersListItem = ({
  id,
  user_name,
  collections,
  policy,
  pc,
  os,
  last_seen,
}) => {
  const profilesList = useSelector((state) => state.profiles.profiles);

  return (
    <TableRow
      key={id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableBodyCell scope="row">{user_name}</TableBodyCell>
      <TableBodyCell align="left">{collections}</TableBodyCell>
      <TableBodyCell align="left">
        {profilesList.map((profile) =>
          profile.id === policy ? profile.name : ''
        )}
      </TableBodyCell>
      <TableBodyCell align="left">{pc}</TableBodyCell>
      <TableBodyCell align="left">{os}</TableBodyCell>
      <TableBodyCell align="left">{last_seen}</TableBodyCell>
    </TableRow>
  );
};

export default UsersListItem;
