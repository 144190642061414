// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppBar_MuiToolbar-root__\\+z\\+a2 {\n  padding: 8px 0 !important;\n  background: #f6f9ff;\n}\n@media (min-width: 900px) {\n  .AppBar_MuiToolbar-root__\\+z\\+a2 {\n    display: none;\n  }\n}\n\n.AppBar_MuiIconButton-root__PRL\\+R {\n  margin-left: auto;\n  padding: 8px;\n  border: 1px solid #ced2da;\n  border-radius: 8px;\n}\n\n.AppBar_MuiDrawer-root__IYDqh {\n  z-index: 10;\n}\n\n.AppBar_MuiPaper-root__14Eeu {\n  top: 55px;\n}", "",{"version":3,"sources":["webpack://./src/components/appbar/AppBar.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,mBCHO;ADET;AAGE;EAJF;IAKI,aAAA;EAAF;AACF;;AAGA;EACE,iBAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,WAAA;AAAF;;AAGA;EACE,SAAA;AAAF","sourcesContent":["@import '../../styles/variables.scss';\n\n.MuiToolbar-root {\n  padding: 8px 0 !important;\n  background: $nav-bg;\n\n  @media (min-width: 900px) {\n    display: none;\n  }\n}\n\n.MuiIconButton-root {\n  margin-left: auto;\n  padding: 8px;\n  border: 1px solid $Neutral-Gray-300;\n  border-radius: 8px;\n}\n\n.MuiDrawer-root {\n  z-index: 10;\n}\n\n.MuiPaper-root {\n  top: 55px;\n}\n","$text-color: #141c24;\n$nav-bg: #f6f9ff;\n$nav-selected: #cadcfd;\n$primary: #1c66f6;\n$primary100: #e1effe;\n$primary700: #0052cc;\n$neutral-gray-200: #e4e7ec;\n$neutral-gray-500: #637083;\n$stroke1: #eceff5;\n$stroke2: #e4edfe;\n$widget-bg: #fafcff;\n$Neutral-Gray-200: #e4e7ec;\n$Neutral-Gray-300: #ced2da;\n$Gray-1: #333;\n$Gradient-Success: linear-gradient(80deg, #40bf7f 0%, #7ad65c 100%);\n$warning: #f64c4c;\n$white: #fff;\n$orange: #ec7d0d;\n$success100: #d9f2e5;\n$success: #309161;\n$error50: #fee7e7;\n$error500: #f64c4c;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MuiToolbar-root": "AppBar_MuiToolbar-root__+z+a2",
	"MuiIconButton-root": "AppBar_MuiIconButton-root__PRL+R",
	"MuiDrawer-root": "AppBar_MuiDrawer-root__IYDqh",
	"MuiPaper-root": "AppBar_MuiPaper-root__14Eeu"
};
export default ___CSS_LOADER_EXPORT___;
