import { createTheme } from '@mui/material/styles';

import variables from './styles/export.scss';
import styles from './components/app/App.module.scss';
import buttonStyles from './components/button/Button.module.scss';
import paper from './styles/paper.module.scss';
import select from './styles/select.module.scss';
import inputLabel from './styles/inputLabel.module.scss';
import formControl from './styles/formControl.module.scss';
import tooltip from './styles/tooltip.module.scss';
import notification from './styles/nofication.module.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: variables['primaryMain'],
      contrastText: variables['white'],
    },
    secondary: {
      main: variables['orange'],
    },
    warning: {
      main: variables['warning'],
    },
    error: {
      main: variables['error500'],
    },
    info: {
      main: variables['neutralGray300'],
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        classes: {
          root: buttonStyles['MuiButton-root'],
          outlined: buttonStyles['MuiButton-outlined'],
          contained: buttonStyles['MuiButton-contained'],
          sizeLarge: buttonStyles['MuiButton-sizeLarge'],
          sizeSmall: buttonStyles['MuiButton-sizeSmall'],
          disabled: buttonStyles['Mui-disabled'],
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        classes: {
          root: styles['MuiModal-root'],
          paper: styles['MuiPaper-root'],
        },
      },
    },
    MuiModal: {
      defaultProps: {
        classes: {
          backdrop: styles['MuiBackdrop-root'],
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        classes: {
          root: paper['MuiPaper-root'],
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        native: true,
        classes: {
          root: select['MuiInputBase-root'],
          select: select['MuiNativeSelect-select'],
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        classes: { root: inputLabel['MuiFormLabel-root'] },
      },
    },
    MuiFormControl: {
      defaultProps: {
        classes: {
          root: formControl['MuiFormControl-root'],
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTooltip: {
      defaultProps: {
        classes: {
          tooltip: tooltip['MuiTooltip-tooltip'],
          arrow: tooltip['MuiTooltip-arrow'],
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        'aria-label': 'alert',
        classes: {
          root: notification['MuiAlert-root'],
          colorSuccess: notification['MuiAlert-colorSuccess'],
          colorError: notification['MuiAlert-colorError'],
        },
      },
    },
  },
});

export default theme;
