import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';

// import RadioButtonsGroup from '../radio-button/RadioButtonsGroup';

import { assignedUsers } from '../../store/usersReducer';

import styles from './Users.module.scss';

const UsersAssignedFilter = () => {
  const [assigned, setAssigned] = useState([]);
  const [isAssigned, setIsAssigned] = useState(false);
  const [isUnassigned, setIsUnassigned] = useState(true);
  const [value, setValue] = useState('');

  const dispatch = useDispatch();
  const groups = useSelector((state) => state.groups.groups_list);

  useEffect(() => {
    let groupsMap = [];

    groups.forEach((group) => {
      groupsMap.push(...group.ad_users);
    });

    setAssigned(
      groupsMap.filter((elem, index, self) => {
        return index === self.indexOf(elem);
      })
    );
  }, [groups]);

  useEffect(() => {
    if (isAssigned || isUnassigned) {
      dispatch(
        assignedUsers({
          filter: value,
          data: assigned,
        })
      );
    } else {
      dispatch(
        assignedUsers({
          filter: '',
          data: [],
        })
      );
    }
  }, [isAssigned, isUnassigned, assigned, value, dispatch]);

  const assignedHeandler = (e) => {
    setIsUnassigned(false);
    setValue(e.target.value);
    setIsAssigned(!isAssigned);
  };

  const unassignedHeandler = (e) => {
    setIsAssigned(false);
    setValue(e.target.value);
    setIsUnassigned(!isUnassigned);
  };

  return (
    <div className={styles['users-control-filter']}>
      {/* <RadioButtonsGroup
        label="Show:"
        defaultValue="Unassigned"
        buttons={[
          { value: 'unassigned', label: 'Unassigned' },
          { value: 'assigned', label: 'Assigned' },
        ]}
      /> */}
      <Button
        variant={isUnassigned ? 'contained' : 'outlined'}
        size="small"
        onClick={unassignedHeandler}
      >
        Unassigned
      </Button>
      <Button
        variant={isAssigned ? 'contained' : 'outlined'}
        size="small"
        onClick={assignedHeandler}
      >
        Assigned
      </Button>
    </div>
  );
};

export default UsersAssignedFilter;
