import MuiTooltip from '@mui/material/Tooltip';

import styles from './Tooltip.module.scss';

const Tooltip = ({ title, children }) => (
  <MuiTooltip
    arrow
    title={title}
    classes={{
      tooltip: styles['MuiTooltip-tooltip'],
      arrow: styles['MuiTooltip-arrow'],
    }}
  >
    {children}
  </MuiTooltip>
);

export default Tooltip;
