// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextField_MuiInputBase-root__MZ\\+sw {\n  padding: 11.5px 8px;\n  line-height: 24px;\n  border: 1px solid #ced2da;\n  border-radius: 8px;\n  background: #fff;\n}\nlabel + .TextField_MuiInputBase-root__MZ\\+sw {\n  margin-top: 20px;\n}\n\n.TextField_MuiInputBase-input__KTGvc {\n  padding: 0;\n}\n\n.TextField_MuiInputBase-sizeSmall__v4VUj {\n  padding: 8px 12px;\n}", "",{"version":3,"sources":["webpack://./src/components/text-field/TextField.module.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;AADF;AAGE;EACE,gBAAA;AADJ;;AAKA;EACE,UAAA;AAFF;;AAKA;EACE,iBAAA;AAFF","sourcesContent":["@import '../../styles/variables.scss';\n\n.MuiInputBase-root {\n  padding: 11.5px 8px;\n  line-height: 24px;\n  border: 1px solid $Neutral-Gray-300;\n  border-radius: 8px;\n  background: #fff;\n\n  label + & {\n    margin-top: 20px;\n  }\n}\n\n.MuiInputBase-input {\n  padding: 0;\n}\n\n.MuiInputBase-sizeSmall {\n  padding: 8px 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MuiInputBase-root": "TextField_MuiInputBase-root__MZ+sw",
	"MuiInputBase-input": "TextField_MuiInputBase-input__KTGvc",
	"MuiInputBase-sizeSmall": "TextField_MuiInputBase-sizeSmall__v4VUj"
};
export default ___CSS_LOADER_EXPORT___;
