const GET_PROFILES = "GET_PROFILES";
const GET_PROFILES_SUCCESS = "GET_PROFILES_SUCCESS";
const GET_PROFILES_FAILURE = "GET_PROFILES_FAILURE";

const initialState = {
    profiles: [],
    loading: false,
    error: false,
};

export default function profilesReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PROFILES:
            return {
                ...state,
                loading: true
            }
        case GET_PROFILES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                profiles: action.payload
            }
        case GET_PROFILES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                profiles: []
            }
        default:
            return state
    };
};

export const getProfiles = () => (
    {
        type: GET_PROFILES
    }
);

export const getProfilesSuccess = (profiles) => (
    {
        type: GET_PROFILES_SUCCESS,
        payload: profiles
    }
);

export const getProfilesFailure = (error) => (
    {
        type: GET_PROFILES_FAILURE,
        payload: error
    }
);
