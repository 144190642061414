import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';

import styles from './Header.module.scss';

const Header = () => {
  const isAuthData = useSelector((state) => state.auth.auth);

  return (
    <header className={styles['header']}>
      <Typography component="h1" className={styles['header-title']}>
        Active directory
      </Typography>
      <div className={styles['header-controls']}>
        {isAuthData.permissions.is_staff && (
          <a
            href="https://www.safedns.com/you-were-never-here/"
            className={styles['header-controls-dashboard']}
          >
            {' '}
          </a>
        )}

        <a
          href="https://www.safedns.com/cabinet/billing/"
          className={styles['header-controls-billing']}
        >
          {' '}
        </a>

        <a
          href="https://www.safedns.com/cabinet/account/"
          className={styles['header-controls-settings']}
        >
          {' '}
        </a>

        <span className={styles['header-controls-account']}>
          {isAuthData.username}
        </span>
        {isAuthData.is_authenticated && (
          <a
            href="https://www.safedns.com/auth/logout/"
            className={styles['header-controls-logout']}
          >
            {' '}
          </a>
        )}
      </div>
    </header>
  );
};

export default Header;
