import { getAuthKeySuccess, getAuthKeyFailure } from '../store/usersReducer';
import axios from 'axios';

import { domain } from '../api/httpClient';

export const fetchAuthKeyAd = (user_id) => {
  return function (dispatch) {
    axios({
      method: 'GET',
      url: `${domain}/ad/rest_api/v1/users/${user_id}/ad_auth_key/`,
      withCredentials: true,
    })
      .then((response) => {
        dispatch(getAuthKeySuccess(response.data.auth_key));
      })
      .catch(() => {
        dispatch(getAuthKeyFailure());
      });
  };
};
