import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import AuthContext from '../../contexts/authContext';

import styles from './SubMenu.module.scss';

const SubMenu = () => {
  const { menuAdItem } = useContext(AuthContext);
  const subMenuItems = menuAdItem.find((item) =>
    window.location.pathname.includes(item.name)
  ).children;

  return (
    <div className={styles['sub-menu']}>
      {/* {menu.map(menuItem => (
                menuItem.children.map((submenuItem, index) => (
                    window.location.pathname.includes(menuItem.url) &&
                    <NavLink
                        key={index}
                        to={submenuItem.url}
                        className={({isActive}) =>
                            `${`sub-menu-item-${isActive ? "active-" : ""}${submenuItem.title.toLowerCase()}`}`
                        }
                    >{submenuItem.title}
                    </NavLink>
                ))
            ))} */}
      {subMenuItems.map((item, index) => (
        <NavLink
          key={index}
          to={item.url}
          className={({ isActive }) => {
            const activeCls = isActive ? 'active' : '';

            return `${styles['sub-menu-item']} ${styles[activeCls]}`;
          }}
        >
          {item.title}
        </NavLink>
      ))}
    </div>
  );
};

export default SubMenu;
