import React from "react";
import './FetchError.scss';

const FetchError = () => {
    return (
        <div className="fetch-error">
            <div className="fetch-error-img"></div>
            <p className="fetch-error-text">Something went wrong.</p>
            <p className="fetch-error-text">Please try again a bit later</p>
        </div>
    );
};

export default FetchError;
