import React from 'react';

import common from '../app/App.module.scss';
import styles from './Footer.module.scss';

const Footer = () => {
  const thisYear = new Date().getFullYear();

  return (
    <footer className={styles['footer']}>
      <div className={styles['year']}>
        &copy; {thisYear} SafeDNS - All rights reserved
      </div>
      <div className={styles['footer-social']}>
        <a
          href="https://www.instagram.com/safedns/"
          className={styles['instagram']}
          rel="noreferrer"
          aria-label="Instagram"
        >
          <span className={common['offscreen']}>Instagram</span>
        </a>
        <a
          href="https://www.facebook.com/safednsofficial?mibextid=ZbWKwL"
          className={styles['facebook']}
          rel="noreferrer"
          aria-label="Facebook"
        >
          <span className={common['offscreen']}>Facebook</span>
        </a>
        <a
          href="https://twitter.com/SafeDNS"
          className={styles['x']}
          rel="noreferrer"
          aria-label="X"
        >
          <span className={common['offscreen']}>X</span>
        </a>
        <a
          href="https://www.linkedin.com/company/safedns"
          className={styles['linkedin']}
          rel="noreferrer"
          aria-label="Linkedin"
        >
          <span className={common['offscreen']}>LinkedIn</span>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
