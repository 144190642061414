import React from 'react';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import './GoBack.scss';

const ArrowButton = ({ url, direction = 'left', title }) => {
  return (
    <div className="go-back">
      <Button
        component={NavLink}
        to={url}
        startIcon={<ArrowBackIosNewIcon sx={{ fontSize: '16px' }} />}
      >
        {title && <span>{title}</span>}
      </Button>
    </div>
  );
};

export default ArrowButton;
