import { TableCell } from '@mui/material';

import styles from './TableCells.module.scss';

const TableHeadCell = ({ align, children }) => {
  return (
    <TableCell
      align={align}
      component="th"
      classes={{ root: styles['MuiTableCell-head'] }}
    >
      {children}
    </TableCell>
  );
};

export { TableHeadCell };

const TableBodyCell = ({ align, children }) => {
  return (
    <TableCell align={align} classes={{ root: styles['MuiTableCell-body'] }}>
      {children}
    </TableCell>
  );
};

export { TableBodyCell };
