import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Stack, Grid, Paper, Button } from '@mui/material';

import Search from '../UI/search/Search';
import CollectionsList from './CollectionsList';
import CollectionsEmptyCreate from './CollectionsEmptyCreate';
import FetchError from '../UI/fetch-error/FetchError';
import Loader from '../UI/loader/Loader';

// TODO: delte scss file
// import './Collections.scss';

const Collections = () => {
  const { error, loading } = useSelector((state) => state.collections);
  let collections = useSelector((state) => state.collections.collections_list);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <FetchError />;
  }

  return (
    <Paper>
      {collections.length ? (
        <>
          <Stack
            gap={2}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={{ md: 'space-between' }}
            alignItems={{ md: 'flex-end' }}
          >
            <Grid item md={4}>
              <Search placeholder="Collection" />
            </Grid>
            <Grid item>
              <Button
                to="/collections/create"
                variant="contained"
                size="small"
                component={NavLink}
              >
                New Collection
              </Button>
            </Grid>
          </Stack>
          <CollectionsList />
        </>
      ) : (
        <CollectionsEmptyCreate />
      )}
    </Paper>
  );
};

export default Collections;
