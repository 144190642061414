import { SvgIcon } from '@mui/material';

const Edit = () => (
  <SvgIcon>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M16.8474 7.74528L8.25277 16.34C7.97356 16.6192 7.61796 16.8095 7.23078 16.887L4.58367 17.4164L5.11309 14.7693C5.19053 14.3821 5.38084 14.0265 5.66003 13.7473L14.2547 5.15255M16.8474 7.74528L18.0848 6.50784C18.4754 6.11732 18.4754 5.48415 18.0848 5.09363L16.9063 3.91512C16.5158 3.52459 15.8826 3.52459 15.4921 3.91511L14.2547 5.15255M16.8474 7.74528L14.2547 5.15255"
        stroke="#141C24"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default Edit;
