import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SvgIcon, Grid } from '@mui/material';

import TextField from '../text-field/TextField';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import { checkedCollectionItems } from '../../store/collectionsReducer';

import './Collections.scss';

const GroupIcon = () => (
  <SvgIcon>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 19V18C3 15.7909 4.79086 14 7 14H11C13.2091 14 15 15.7909 15 18V19M15 11C16.6569 11 18 9.65685 18 8C18 6.34315 16.6569 5 15 5M21 19V18C21 15.7909 19.2091 14 17 14H16.5M12 8C12 9.65685 10.6569 11 9 11C7.34315 11 6 9.65685 6 8C6 6.34315 7.34315 5 9 5C10.6569 5 12 6.34315 12 8Z"
        stroke="#598FF9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

const PersonIcon = () => (
  <SvgIcon>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 20V19C5 16.2386 7.23858 14 10 14H14C16.7614 14 19 16.2386 19 19V20M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
        stroke="#4783F8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

const CollectionsConfigurate = ({ collectionName, collectionUsers }) => {
  const dispatch = useDispatch();
  const DOMAINS = useSelector((state) => state.domains.domains_list);
  const GROUPS = useSelector((state) => state.groups.groups_list);
  const USERS = useSelector((state) => state.users.users_list);

  const treeIcons = {
    check: <CheckBoxIcon color="primary" />,
    uncheck: <CheckBoxOutlineBlankIcon color="info" />,
    expandClose: <KeyboardArrowRightIcon />,
    expandOpen: <KeyboardArrowDownIcon />,
    halfCheck: <IndeterminateCheckBoxIcon color="info" />,
    // parentClose: <KeyboardArrowRightIcon />,
    // parentOpen: <KeyboardArrowDownIcon />,
  };

  const rightTreeIcons = {
    check: <CheckBoxIcon color="primary" />,
    uncheck: <CheckBoxOutlineBlankIcon color="info" />,
    parentClose: <GroupIcon />,
    parentOpen: <GroupIcon />,
    leaf: <PersonIcon />,
  };

  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [result, setIsResult] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [nodesFiltered, setNodesFiltered] = useState([]);
  const [lastClilds, setLastChilds] = useState([]);
  const [breadcrumbs, setIsBreadcrumbs] = useState('');
  // const [updateChecked, setUpdateChecked] = useState([]);
  // const [targetNode, setTargetNode] = useState([]);

  let isData = [];
  let checkedList = [];
  let checkedUsers = [];
  let resultChecked = [];

  useEffect(() => {
    collectionUsers &&
      GROUPS.forEach((group) => {
        //checking for a match of selected users in the tree
        const filterSelected = group.ad_users.filter((element) =>
          collectionUsers.includes(element)
        );

        filterSelected.map((userId) => {
          checkedUsers.push(
            `{\"ad_users\":\"${DOMAINS.map((domain) =>
              domain.id === group.domain ? 'd' + domain.id : null
            )}-g${
              group.parent !== null ? group.parent + '-g' + group.id : group.id
            }-u${USERS.map((user) =>
              user.id === userId ? user.id : null
            )}\"}`.replace(/[\s,%]/g, '')
          );
        });
      });

    collectionUsers && setChecked(checkedUsers);
  }, [collectionUsers]);

  useEffect(() => {
    setIsResult([
      {
        value: '{}',
        label: 'Select All',
        children: isData,
      },
    ]);
  }, [DOMAINS, GROUPS, USERS]);

  useEffect(() => {
    result.length && setNodesFiltered(result);
  }, [result]);

  useEffect(() => {
    checked.map(
      (check) =>
        check.includes('ad_users') &&
        checkedList.push(JSON.parse(check).ad_users.split('u')[1])
    );

    resultChecked = checkedList.filter((elem, index, self) => {
      return index === self.indexOf(elem);
    });

    dispatch(checkedCollectionItems(resultChecked));
  }, [checked]);

  useEffect(() => {
    DOMAINS.map((domain) =>
      isData.push({
        value: JSON.stringify({
          id: domain.id,
        }),
        title: `${domain.name}`,
        label: domain.name,
        children: GROUPS.length
          ? GROUPS.filter(
              (group) => group.domain === domain.id && group.parent == null
            ).map((group) => ({
              value: JSON.stringify({
                id: `d${domain.id}-g${group.id}`,
              }),
              title: `${domain.name}/${group.name}`,
              label: group.name,
              children: [
                // !group.is_leaf &&
                ...GROUPS.filter((child) => group.id === child.parent).map(
                  (child_f) => ({
                    value: JSON.stringify({
                      id: `d${domain.id}-g${group.id}-g${child_f.id}`,
                    }),
                    title: `${domain.name}/${group.name}/${child_f.name}`,
                    label: child_f.name,
                    children: USERS.filter(
                      (user) => child_f.ad_users.indexOf(user.id) !== -1
                    ).map((user_f) => ({
                      value: JSON.stringify({
                        ad_users: `d${domain.id}-g${group.id}-g${child_f.id}-u${user_f.id}`,
                      }),
                      title: `${domain.name}/${group.name}/${child_f.name}/${user_f.name}`,
                      label: user_f.name,
                    })),
                  })
                ),

                ...USERS.filter(
                  (user) => group.ad_users.indexOf(user.id) !== -1
                ).map((user_g) => ({
                  value: JSON.stringify({
                    ad_users: `d${domain.id}-g${group.id}-u${user_g.id}`,
                  }),
                  title: `${domain.name}/${group.name}/${user_g.name}`,
                  label: user_g.name,
                })),
              ],
            }))
          : null,
      })
    );
  }, [DOMAINS, GROUPS, USERS]);

  // FILTERED TREE

  const filterNodes = (filtered, node) => {
    const children = (node.children || []).reduce(filterNodes, []);

    if (
      node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) >
        -1 ||
      children.length
    ) {
      filtered.push({ ...node, ...(children.length && { children }) });
    }

    return filtered;
  };

  const filterTree = () => {
    if (!filterText) {
      setNodesFiltered(result);

      return;
    }

    setNodesFiltered(result.reduce(filterNodes, []));
  };

  const onFilterHandler = (e) => {
    setFilterText(e.target.value);

    e.target.value ? filterTree() : setNodesFiltered(result);
  };

  return (
    <div className="collections-form-configurate-wrapper">
      <Grid container pt={3} pb={2}>
        <Grid item xs={12} md={3}>
          <TextField
            id="tree-search"
            label="Search"
            placeholder="Domain, group, team, user"
            value={filterText}
            size="small"
            onChange={(e) => onFilterHandler(e)}
          />
        </Grid>
      </Grid>
      <h3>Users</h3>
      <div className="collections-form-configurate">
        <div className="collections-form-configurate-control">
          <div className="collections-form-configurate-control-breadcrumbs">
            {`${
              collectionName ? collectionName : 'Collection name'
            } / ${breadcrumbs}`}
          </div>
        </div>
        <div className="collections-form-configurate-items">
          <div className="collections-form-configurate-items-tree">
            {result.length && (
              <CheckboxTree
                nodes={nodesFiltered}
                checked={checked}
                expanded={expanded}
                onCheck={(checked, targetNode) => {
                  setChecked(checked);
                }}
                onExpand={(expanded) => setExpanded(expanded)}
                showNodeIcon={false}
                expandOnClick={true}
                onClick={(expanded) => {
                  setIsBreadcrumbs(
                    expanded.parent.title !== undefined
                      ? expanded.parent.title
                      : ''
                  );

                  if (expanded.children) {
                    expanded.children.forEach((childs) => {
                      if (childs.hasOwnProperty('children')) {
                        setLastChilds([]);
                      } else {
                        setLastChilds(expanded.children);
                      }
                    });
                  }
                }}
                icons={treeIcons}
              />
            )}
          </div>

          <div className="collections-form-configurate-items-users">
            {lastClilds.length ? (
              <div className="collections-form-configurate-items-users-container">
                <CheckboxTree
                  nodes={lastClilds}
                  checked={checked}
                  expanded={expanded}
                  // onCheck={(checked) => setChecked(checked)}
                  onExpand={(expanded) => setExpanded(expanded)}
                  showNodeIcon
                  icons={rightTreeIcons}
                />
                <span className="collections-form-configurate-items-users-container-counter">
                  {lastClilds.length} Users
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionsConfigurate;

//TODO EDIT COLLECTION

// setTargetNode(targetNode);

// let unchecked = [];

// if (!targetNode.checked) {
//     const targetNodeUsers = (arr) => {
//         if (arr.children) {
//             arr.children.forEach(item => {
//                 unchecked.push(item)

//                 if (item.hasOwnProperty("children")) {
//                     targetNodeUsers(item)
//                 }
//             })
//         } else {
//             unchecked.push({
//                 label: arr.label,
//                 title: "",
//                 value: arr.value
//             })
//         }
//     }
//     targetNodeUsers(targetNode);

//     const removedItems = unchecked
//     .filter(item => !item.hasOwnProperty("children"))
//     .map(ch => JSON.parse(ch.value).ad_users.split('u')[1])

//     setUpdateChecked(removedItems);
// }

//TODO EDIT COLLECTION

// let initialCheckedUsers = collectionData.ad_users;

// useEffect(() => {
//     if (!targetNode.checked) {
//         const updateCheckedNums = updateChecked.map(Number);

//         const removeItems = (array, itemToRemove) => {
//             return array.filter(v => {
//                 return !itemToRemove.includes(v);
//             });
//         }

//         console.log(removeItems(initialCheckedUsers, updateCheckedNums));
//         // console.log(updateCheckedNums);
//         // console.log(initialCheckedUsers);

//         dispatch(collectionUsersUpdate(removeItems(initialCheckedUsers, updateCheckedNums)));
//     }
//     else {
//         // initialCheckedUsers.push(Number(...updateChecked))
//         console.log(initialCheckedUsers);
//     }
// }, [checked, updateChecked]);
