import {
  getCollectionsList,
  getCollectionsListSuccess,
  getCollectionsListFailure,
} from '../store/collectionsReducer';
import axios from 'axios';

import { domain } from '../api/httpClient';

export const fetchCollectionsList = (user_id) => {
  return function (dispatch) {
    dispatch(getCollectionsList());

    axios({
      method: 'GET',
      url: `${domain}/ad/rest_api/v1/users/${user_id}/collections/`,
      withCredentials: true,
    })
      .then((response) => {
        dispatch(getCollectionsListSuccess(response.data.results));
      })
      .catch(() => {
        dispatch(getCollectionsListFailure());
      });
  };
};
