import React from 'react';
import { Select as MuiSelect, InputLabel, FormControl } from '@mui/material';

export default function Select({
  id,
  label,
  value,
  children,
  error,
  onChange,
}) {
  return (
    <FormControl variant="standard">
      <InputLabel shrink htmlFor={id}>
        {label}
      </InputLabel>
      <MuiSelect native id={id} value={value} error={error} onChange={onChange}>
        {children}
      </MuiSelect>
    </FormControl>
  );
}
