import {
  getDomainsList,
  getDomainsListSuccess,
  getDomainsListFailure,
} from '../store/domainsReducer';
import axios from 'axios';

import { domain } from '../api/httpClient';

export const fetchDomainsList = (user_id) => {
  return function (dispatch) {
    dispatch(getDomainsList());

    axios({
      method: 'GET',
      url: `${domain}/ad/rest_api/v1/users/${user_id}/agent_domains/`,
      withCredentials: true,
    })
      .then((response) => {
        dispatch(getDomainsListSuccess(response.data.results));
      })
      .catch(() => {
        dispatch(getDomainsListFailure());
      });
  };
};
