import { Stack, Grid, Button, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

import img from '../../icons/no-collections.svg';

const CollectionsEmptyCreate = () => {
  return (
    <Stack direction={{ md: 'row' }} gap={3} alignItems={{ md: 'center' }}>
      <Grid item xs={12} md={6}>
        <Stack alignItems="flex-start">
          <Typography sx={{ pb: 2, fontWeight: 500, color: 'primary.main' }}>
            Collection is a way of grouping users within Active Directory.{' '}
            <br />
            Create your first collection and select a filtering policy for it.
          </Typography>
          <Button
            to="/"
            component={Link}
            variant="text"
            color="secondary"
            sx={{ p: 0 }}
          >
            Read about Active Directory
          </Button>
          <Box sx={{ pt: 4 }} />
          <Button
            component={Link}
            variant="contained"
            size="large"
            to="/collections/create"
          >
            Create
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box maxWidth={414}>
          <img src={img} width="100%" height="auto" alt="no collections yet" />
        </Box>
      </Grid>
    </Stack>
  );
};

export default CollectionsEmptyCreate;
