import React from 'react';
import { Grid, Box } from '@mui/material';

import CreateDomain from './CreateDomain';
import ConfigurateAd from './ConfigurateAd';
import DomainsList from './DomainsList';
import './Domains.scss';

import { SecondaryPaper } from '../papers/Papers';
import Notification from '../UI/notification/Notification';

const Domains = () => {
  return (
    <Box className="domains" sx={{ position: 'relative' }}>
      <Grid container spacing={4} className="domains-header">
        <Grid item xs={12} md={6}>
          <SecondaryPaper>
            <CreateDomain />
          </SecondaryPaper>
        </Grid>
        <Grid item xs={12} md={6}>
          <SecondaryPaper>
            <ConfigurateAd />
          </SecondaryPaper>
        </Grid>
      </Grid>
      <DomainsList />
      <Notification />
    </Box>
  );
};

export default Domains;
