// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../icons/instagram.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../icons/facebook.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../icons/x.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../icons/linkedin.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer_footer__cOQNQ {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-top: auto;\n  padding: 24px 0;\n}\n.Footer_footer-social__jKQ-i {\n  display: flex;\n  gap: 12px;\n}\n.Footer_footer-social__jKQ-i a {\n  width: 24px;\n  height: 24px;\n}\n.Footer_footer-social__jKQ-i .Footer_instagram__2a-ra {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.Footer_footer-social__jKQ-i .Footer_facebook__czcpk {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.Footer_footer-social__jKQ-i .Footer_x__QgDDE {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.Footer_footer-social__jKQ-i .Footer_linkedin__FtVDs {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}", "",{"version":3,"sources":["webpack://./src/components/footer/Footer.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,gBAAA;EACA,eAAA;AACF;AACE;EACE,aAAA;EACA,SAAA;AACJ;AACI;EACE,WAAA;EACA,YAAA;AACN;AAEI;EACE,mDAAA;AAAN;AAGI;EACE,mDAAA;AADN;AAII;EACE,mDAAA;AAFN;AAKI;EACE,mDAAA;AAHN","sourcesContent":[".footer {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-top: auto;\n  padding: 24px 0;\n\n  &-social {\n    display: flex;\n    gap: 12px;\n\n    a {\n      width: 24px;\n      height: 24px;\n    }\n\n    .instagram {\n      background: url('../../icons/instagram.svg');\n    }\n\n    .facebook {\n      background: url('../../icons/facebook.svg');\n    }\n\n    .x {\n      background: url('../../icons/x.svg');\n    }\n\n    .linkedin {\n      background: url('../../icons/linkedin.svg');\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "Footer_footer__cOQNQ",
	"footer-social": "Footer_footer-social__jKQ-i",
	"instagram": "Footer_instagram__2a-ra",
	"facebook": "Footer_facebook__czcpk",
	"x": "Footer_x__QgDDE",
	"linkedin": "Footer_linkedin__FtVDs"
};
export default ___CSS_LOADER_EXPORT___;
