const GET_AD_USERS_WITH_PROFILES = "GET_AD_USERS_WITH_PROFILES";
const GET_AD_USERS_WITH_PROFILES_SUCCESS = "GET_AD_USERS_WITH_PROFILES_SUCCESS";
const GET_AD_USERS_WITH_PROFILES_FAILURE = "GET_AD_USERS_WITH_PROFILES_FAILURE";

const initialState = {
    ad_users_with_profiles: [],
    loading: false,
    error: false,
};

export default function adUsersWithProfilesReducer(state = initialState, action) {
    switch (action.type) {
        case GET_AD_USERS_WITH_PROFILES:
            return {
                ...state,
                loading: true
            }
        case GET_AD_USERS_WITH_PROFILES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                ad_users_with_profiles: action.payload
            }
        case GET_AD_USERS_WITH_PROFILES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                ad_users_with_profiles: []
            }
        default:
            return state
    };
};

export const getAdUsersWithProfiles = () => (
    {
        type: GET_AD_USERS_WITH_PROFILES
    }
);

export const getAdUsersWithProfilesSuccess = (ad_users_with_profiles) => (
    {
        type: GET_AD_USERS_WITH_PROFILES_SUCCESS,
        payload: ad_users_with_profiles
    }
);

export const getAdUsersWithProfilesFailure = (error) => (
    {
        type: GET_AD_USERS_WITH_PROFILES_FAILURE,
        payload: error
    }
);
