import React from 'react';
import { Grid } from '@mui/material';
import './Paginator.scss';

const Paginator = ({ nextPage, prevPage, page, gaps, setPage, totalPages }) => {
  return (
    <Grid container sx={{ pt: 2, pb: 2, justifyContent: 'flex-end' }}>
      <div className="paginator">
        <button
          onClick={prevPage}
          className={`previous ${page === 1 ? 'disabled' : ''}`}
        />
        <button
          onClick={() => setPage(1)}
          className={`page ${page === 1 ? 'active' : ''}`}
        >
          1
        </button>
        {gaps.before ? '...' : null}
        {gaps.paginationGroup.map((el) => (
          <button
            onClick={() => setPage(el)}
            key={el}
            className={`page ${page === el ? 'active' : ''}`}
          >
            {el}
          </button>
        ))}
        {gaps.after ? '...' : null}
        <button
          onClick={() => setPage(totalPages)}
          className={`page ${page === totalPages ? 'active' : ''}`}
        >
          {totalPages}
        </button>
        <button
          onClick={nextPage}
          className={`next ${page === totalPages ? 'disabled' : ''}`}
        />
      </div>
    </Grid>
  );
};

export default Paginator;
