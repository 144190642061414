import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { SvgIcon } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { NavLink } from 'react-router-dom';

import AuthContext from '../../contexts/authContext';

import styles from './Menu.module.scss';
import headerStyles from '../header/Header.module.scss';
import appStyles from '../app/App.module.scss';

import MainIcon from '../../icons/menu.svg';
import Settings from '../../icons/settings.svg';
import Gear from '../../icons/gear.svg';
import List from '../../icons/list.svg';
import CheckedList from '../../icons/checked-list.svg';
import Stop from '../../icons/stop.svg';
import Network from '../../icons/network.svg';
import ChartRaises from '../../icons/chart-raises.svg';
import Group from '../../icons/group.svg';
import Help from '../../icons/help.svg';
import Account from '../../icons/account.svg';

const menuIcons = {
  Main: MainIcon,
  'User administration': Settings,
  Settings: Gear,
  ActiveDirectory: Network,
  Categories: List,
  Allowlist: CheckedList,
  Denylist: Stop,
  Stats: ChartRaises,
  'New Stats (Beta)': ChartRaises,
  Partners: Group,
  Help: Help,
  Account: Account,
};

// TODO: optimize custom icons imports
const CollapseIcon = () => (
  <SvgIcon>
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16699 13.4456L9.29322 8.31941C9.68374 7.92889 10.3169 7.92889 10.7074 8.31941L15.8337 13.4456"
        stroke="#141C24"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

const ExpandIcon = () => (
  <SvgIcon>
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 7.69531L9.70711 12.2382C9.31658 12.6287 8.68342 12.6287 8.29289 12.2382L3.75 7.69531"
        stroke="#141C24"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

const Menu = () => {
  const { menu } = useContext(AuthContext);
  const isAuthData = useSelector((state) => state.auth.auth);

  return (
    <nav className={styles['menu']}>
      {menu.map((item) => {
        const icon = menuIcons[item.title];

        if (item.children.length) {
          return (
            <SimpleTreeView
              key={Math.random()}
              slots={{
                expandIcon: ExpandIcon,
                collapseIcon: CollapseIcon,
              }}
            >
              <TreeItem
                itemId={item.title}
                label={
                  <>
                    <img src={icon} alt="menu icon" />
                    {item.title}
                  </>
                }
                classes={{
                  iconContainer: styles['MuiTreeItem-iconContainer'],
                  label: styles['MuiTreeItem-label'],
                  content: styles['MuiTreeItem-content'],
                }}
                // Didn't get how to override padding with css classes
                sx={{
                  '& .MuiTreeItem-content': {
                    gap: 4,
                    padding: '12px 8px 12px 8px',

                    '@media (min-width: 1000px)': {
                      gap: 1,
                      padding: '12px 8px 12px 16px',
                    },
                  },
                }}
              >
                {item.children.map((item) => (
                  <NavLink
                    key={Math.random()}
                    to={`${process.env.REACT_APP_BASE_URL}${item.url}`}
                    className={
                      item.url.includes('agent_ad')
                        ? styles['menu-item-active']
                        : styles['menu-item-child']
                    }
                  >
                    {item.title}
                  </NavLink>
                ))}
              </TreeItem>
            </SimpleTreeView>
          );
        }

        return (
          <NavLink
            key={Math.random()}
            to={item.url}
            className={
              item.url.includes('agent_ad')
                ? styles['menu-item-active']
                : styles['menu-item']
            }
          >
            <img src={icon} alt="menu icon" />
            {item.title}
          </NavLink>
        );
      })}

      <div className={styles['mobile-logout']}>
        <span className={headerStyles['header-controls-account']}>
          {isAuthData.username}
        </span>
        {isAuthData.is_authenticated && (
          <a
            href="https://www.safedns.com/auth/logout/"
            className={headerStyles['header-controls-logout']}
          >
            <span className={appStyles['offscreen']}>Logout</span>
          </a>
        )}
      </div>
    </nav>
  );
};

export default Menu;
