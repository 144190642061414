// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inputLabel_MuiFormLabel-root__sgQHw {\n  font-size: 1.1rem;\n  font-weight: 500;\n  line-height: 20px;\n  color: #141c24;\n}", "",{"version":3,"sources":["webpack://./src/styles/inputLabel.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cCNW;ADKb","sourcesContent":["@import './variables.scss';\n\n.MuiFormLabel-root {\n  font-size: 1.1rem;\n  font-weight: 500;\n  line-height: 20px;\n  color: $text-color;\n}\n","$text-color: #141c24;\n$nav-bg: #f6f9ff;\n$nav-selected: #cadcfd;\n$primary: #1c66f6;\n$primary100: #e1effe;\n$primary700: #0052cc;\n$neutral-gray-200: #e4e7ec;\n$neutral-gray-500: #637083;\n$stroke1: #eceff5;\n$stroke2: #e4edfe;\n$widget-bg: #fafcff;\n$Neutral-Gray-200: #e4e7ec;\n$Neutral-Gray-300: #ced2da;\n$Gray-1: #333;\n$Gradient-Success: linear-gradient(80deg, #40bf7f 0%, #7ad65c 100%);\n$warning: #f64c4c;\n$white: #fff;\n$orange: #ec7d0d;\n$success100: #d9f2e5;\n$success: #309161;\n$error50: #fee7e7;\n$error500: #f64c4c;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MuiFormLabel-root": "inputLabel_MuiFormLabel-root__sgQHw"
};
export default ___CSS_LOADER_EXPORT___;
