const GET_CABINET_MENU = "GET_CABINET_MENU";
const GET_CABINET_MENU_SUCCESS = "GET_CABINET_MENU_SUCCESS";
const GET_CABINET_MENU_FAILURE = "GET_CABINET_MENU_FAILURE";

const initialState = {
    cabinet_menu: [],
    loading: false,
    error: false,
};

export default function cabinetMenuReducer(state = initialState, action) {
    switch (action.type) {
        case GET_CABINET_MENU:
            return {
                ...state,
                loading: true
            }
        case GET_CABINET_MENU_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                cabinet_menu: action.payload
            }
        case GET_CABINET_MENU_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                cabinet_menu: []
            }
        default:
            return state
    };
};

export const getCabinetMenu = () => (
    {
        type: GET_CABINET_MENU
    }
);

export const getCabinetMenuSuccess = (cabinet_menu) => (
    {
        type: GET_CABINET_MENU_SUCCESS,
        payload: cabinet_menu
    }
);

export const getCabinetMenuFailure = (error) => (
    {
        type: GET_CABINET_MENU_FAILURE,
        payload: error
    }
);
