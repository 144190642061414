// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".select_MuiInputBase-root__vythj {\n  margin-top: 20px !important;\n  padding: 8px 12px 8px 8px;\n  border-radius: 8px;\n  border: 1px solid #ced2da;\n}\n.select_MuiInputBase-root__vythj::before {\n  content: none;\n}\n.select_MuiInputBase-root__vythj::after {\n  content: none;\n}\n\n.select_MuiNativeSelect-select__7sW1w {\n  border: 0;\n  padding: 0;\n  line-height: 24px;\n  border-radius: 8px;\n  background: #fff;\n}", "",{"version":3,"sources":["webpack://./src/styles/select.module.scss"],"names":[],"mappings":"AAEA;EACE,2BAAA;EACA,yBAAA;EACA,kBAAA;EACA,yBAAA;AADF;AAGE;EACE,aAAA;AADJ;AAIE;EACE,aAAA;AAFJ;;AAMA;EACE,SAAA;EACA,UAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;AAHF","sourcesContent":["@import './variables.scss';\n\n.MuiInputBase-root {\n  margin-top: 20px !important;\n  padding: 8px 12px 8px 8px;\n  border-radius: 8px;\n  border: 1px solid $Neutral-Gray-300;\n\n  &::before {\n    content: none;\n  }\n\n  &::after {\n    content: none;\n  }\n}\n\n.MuiNativeSelect-select {\n  border: 0;\n  padding: 0;\n  line-height: 24px;\n  border-radius: 8px;\n  background: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MuiInputBase-root": "select_MuiInputBase-root__vythj",
	"MuiNativeSelect-select": "select_MuiNativeSelect-select__7sW1w"
};
export default ___CSS_LOADER_EXPORT___;
