// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubMenu_sub-menu__-seQL {\n  max-width: 1230px;\n  display: flex;\n  align-items: center;\n  padding: 24px 0;\n}\n.SubMenu_sub-menu-item__1NUcm {\n  padding: 8px 20px 10px;\n  font-weight: 500;\n  line-height: 24px;\n  color: #637083;\n  text-decoration: none;\n  border-bottom: 2px solid #e4e7ec;\n}\n.SubMenu_sub-menu-item__1NUcm.SubMenu_active__6vHqt {\n  color: #1c66f6;\n  border-bottom: 2px solid #1c66f6;\n}", "",{"version":3,"sources":["webpack://./src/components/sub-menu/SubMenu.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AADF;AAGE;EACE,sBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cCLe;EDMf,qBAAA;EACA,gCAAA;AADJ;AAGI;EACE,cCdI;EDeJ,gCAAA;AADN","sourcesContent":["@import '../../styles/variables.scss';\n\n.sub-menu {\n  max-width: 1230px;\n  display: flex;\n  align-items: center;\n  padding: 24px 0;\n\n  &-item {\n    padding: 8px 20px 10px;\n    font-weight: 500;\n    line-height: 24px;\n    color: $neutral-gray-500;\n    text-decoration: none;\n    border-bottom: 2px solid $neutral-gray-200;\n\n    &.active {\n      color: $primary;\n      border-bottom: 2px solid $primary;\n    }\n  }\n}\n","$text-color: #141c24;\n$nav-bg: #f6f9ff;\n$nav-selected: #cadcfd;\n$primary: #1c66f6;\n$primary100: #e1effe;\n$primary700: #0052cc;\n$neutral-gray-200: #e4e7ec;\n$neutral-gray-500: #637083;\n$stroke1: #eceff5;\n$stroke2: #e4edfe;\n$widget-bg: #fafcff;\n$Neutral-Gray-200: #e4e7ec;\n$Neutral-Gray-300: #ced2da;\n$Gray-1: #333;\n$Gradient-Success: linear-gradient(80deg, #40bf7f 0%, #7ad65c 100%);\n$warning: #f64c4c;\n$white: #fff;\n$orange: #ec7d0d;\n$success100: #d9f2e5;\n$success: #309161;\n$error50: #fee7e7;\n$error500: #f64c4c;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sub-menu": "SubMenu_sub-menu__-seQL",
	"sub-menu-item": "SubMenu_sub-menu-item__1NUcm",
	"active": "SubMenu_active__6vHqt"
};
export default ___CSS_LOADER_EXPORT___;
