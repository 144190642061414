import {
  getCabinetMenu,
  getCabinetMenuSuccess,
  getCabinetMenuFailure,
} from '../store/cabinetMenuReducer';
import axios from 'axios';

import { domain } from '../api/httpClient';

export const fetchCabinetMenu = () => {
  return function (dispatch) {
    dispatch(getCabinetMenu());

    axios({
      method: 'GET',
      url: `${domain}/content/rest_api/v1/cabinet_menu/`,
      withCredentials: true,
    })
      .then((response) => {
        dispatch(getCabinetMenuSuccess(response.data));
      })
      .catch(() => {
        dispatch(getCabinetMenuFailure());
      });
  };
};
