import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { store } from './store';
import { AuthProvider } from './contexts/authContext';
import { ModalProvider } from './contexts/modal-context';
import App from './components/app/App';

import theme from './theme';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <AuthProvider>
      <ModalProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
              <CssBaseline />
              <App />
            </StyledEngineProvider>
          </ThemeProvider>
        </BrowserRouter>
      </ModalProvider>
    </AuthProvider>
  </Provider>
);
