import React from 'react';
import { Grid, Stack } from '@mui/material';

import UsersList from './UsersList';
import Search from '../UI/search/Search';
import UsersAssignedFilter from './UsersAssignedFilter';
import { PrimaryPaper } from '../papers/Papers';

import styles from './Users.module.scss';

const Users = () => {
  return (
    <PrimaryPaper>
      <Stack
        component="form"
        direction={{ md: 'row' }}
        sx={{
          gap: 2,
        }}
        noValidate
        className={styles['users-control']}
      >
        <Grid item xs={12} md={3}>
          <Search placeholder="Users" />
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <UsersAssignedFilter />
        </Grid>
      </Stack>
      <UsersList />
    </PrimaryPaper>
  );
};

export default Users;
