const STORE_TREE = 'STORE_TREE';
const UPDATE_SELECTED_ITEMS = 'UPDATE_SELECTED_ITEMS';
const RIGHT_SELECTED_ITEMS = 'RIGHT_SELECTED_ITEMS';

const initialState = {
  tree: [],
  usersMap: {},
  leftColumnSelectedItems: {},
  rightSelectedItems: [],
  loading: false,
  error: false,
};

export default function treeReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_TREE:
      return {
        ...state,
        tree: action.payload,
      };
    case UPDATE_SELECTED_ITEMS:
      return {
        ...state,
        leftColumnSelectedItems: action.payload,
      };
    case RIGHT_SELECTED_ITEMS:
      return {
        ...state,
        rightSelectedItems: action.payload,
      };

    default:
      return state;
  }
}

export const storeTree = (tree) => {
  return {
    type: STORE_TREE,
    payload: tree,
  };
};

export const updateSelectedUsers = (users) => {
  return {
    type: UPDATE_SELECTED_ITEMS,
    payload: users,
  };
};

export const updateRightSelectedItems = (items) => {
  return {
    type: RIGHT_SELECTED_ITEMS,
    payload: items,
  };
};
